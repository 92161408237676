import { Api } from 'utils';

class AccreditationService {
		
	static admin = {
		getPayments: async (params?: any) => Api.createResponse('admin/accreditations/payments',params),
		getHistory: async (params?: any) => Api.createResponse('admin/accreditations/history',params),
		view: async (params?: any) => Api.createResponse('admin/accreditations/view',params),
		save: async (params?: any) => Api.createResponse('admin/accreditations/save',params),
	}

}

export default AccreditationService;