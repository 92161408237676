import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Constants } from '../utils';
import Menu from 'screens/menu';
import { RootState } from 'reducers';

const _Route = (props: any) => {
	const history = useHistory();
	const module_permissions = props.user?.module_permissions?.filter((i: any) => i.read).map((i: any) => i.module_id) || [];

	if (!props.user && (props.auth || props.admin)) {
		return <Redirect to="/login" />
	}
	else if (props.user && props.admin && props.user.level_id != Constants.LEVELS.ADMIN && props.user.level_id != Constants.LEVELS.MODERATOR) {
		return <Redirect to="/" />
	}
	else if (props.user && (props.user.level_id == Constants.LEVELS.MODERATOR) && props.permission) {
		if (typeof props.permission === 'number') {
			if (module_permissions.indexOf(props.permission) == -1) {
				return <Redirect to="/" />
			}
		}
		else if (Array.isArray(props.permission)) {
			let value = false;
			props.permission.forEach((item: number) => {
				if (!value)
					value = module_permissions.indexOf(props.permission) != -1;
			});
			if (!value) {
				return <Redirect to="/" />
			}
		}		
	}
	else if (props.user && props.noAuth) {
		return <Redirect to="/" />
	}
	if (props.user && (props.auth || props.admin || props.doctor)) {
		return (
			<Menu history={ history }>
				<Route { ...props } />
			</Menu>
		)
	}
	else {
		return <Route { ...props } />
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(_Route);