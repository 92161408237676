import React from 'react';
import { Modal } from 'react-bootstrap';
import { Globals } from 'utils';

const _Modal = React.forwardRef((props: any, ref) => (
	<Modal backdrop="static" animation={ props.animation == null ? true : props.animation } ref={ ref as React.RefObject<any> } id={ props.id } className={ props.className } onShow={ Globals.hideTooltip } show={ props.visible } onHide={ props.onClose }>
		<Modal.Header closeButton>
			{
				typeof props.title == 'string' ? (
					<Modal.Title>{ props.title }</Modal.Title>
				) : (
					<React.Fragment>{ props.title }</React.Fragment>
				)
			}
		</Modal.Header>
		<Modal.Body>{ props.children }</Modal.Body>
	</Modal>
))

export default _Modal;