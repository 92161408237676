import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { ReportService } from 'services';

interface Props {
	onClose?: any;
}

class ModalSettings extends React.Component<Props> {

	state = {
		form: {
			referral_points: '',
			on_time: '',
			prepaid: ''
		},
		points_levels: []
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await ReportService.admin.getSettings();
		this.setState({
			form: {
				referral_points: res.settings?.referral_points || '',
				prepaid: res.points_by_payments?.prepaid || '',
				on_time: res.points_by_payments?.on_time || ''
			},
			points_levels: res.points_levels || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await ReportService.admin.saveSettings({
			...this.state.form,
			points_levels: this.state.points_levels
		});
		Globals.showSuccess("Se ha actualizado la configuración correctamente");
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	changeLevel = (e: any,index: number,callback = () => {}) => {
		let points_levels: any = [...this.state.points_levels];
		points_levels[index][e.target.name] = e.target.value;
		this.setState({
			points_levels
		},callback);
	}
	
	render() {
		return (
			<div id="modal-points-settings">
				<form onSubmit={ this.submit }>
					<div className="row">
						<div className="col-md-4">
							<Input
								type="number"
								value={ this.state.form.referral_points }
								name="referral_points"
								placeholder="0"
								label="Puntos por referido"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-4">
							<Input
								type="number"
								value={ this.state.form.prepaid }
								name="prepaid"
								placeholder="0"
								label="Puntos por pago adelantado"
								onChange={ (e: any) => this.change(e) } />
						</div>
						<div className="col-md-4">
							<Input
								type="number"
								value={ this.state.form.on_time }
								name="on_time"
								placeholder="0"
								label="Puntos por pago a tiempo"
								onChange={ (e: any) => this.change(e) } />
						</div>
					</div>
					{
						this.state.points_levels.map((item: any,index: number) => {
							return (
								<div className="row row-levels">
									<div className="col-md-2">
										<p>Nivel { item.level }</p>
									</div>
									<div className="col-md-5">
										<Input
											type="number"
											value={ item.max_points }
											name="max_points"
											placeholder="0"
											label="Hasta (puntos)"
											onChange={ (e: any) => this.changeLevel(e,index) } />
									</div>
									<div className="col-md-5">
										<Input
											type="decimal"
											value={ item.amount }
											name="amount"
											placeholder="0"
											label="Limite ($)"
											onChange={ (e: any) => this.changeLevel(e,index) } />
									</div>
								</div>
							)
						})
					}
					<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default ModalSettings;