import { Api } from 'utils';

class CommissionService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/commissions/',params),
		save: async (params?: any) => Api.createResponse('admin/commissions/save',params),
	}

}

export default CommissionService;