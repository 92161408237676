import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, Print } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, DownloadExcelIcon } from 'assets/icons';
import { ReportService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	document: '',
	location: '',
	san: ''
}

declare var window: any;

class ReportPayments extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nombre',
			'Nº documento',
			'Ubicación',
			'Nº de San participando',
			'Nº de cuotas canceladas',
			'Monto cancelado $',
			'Fecha de finalización'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Historial de Pagos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await ReportService.admin.getPayments({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
		});
		this.setState({
			data: res.payments.rows,
			last_page: res.payments.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	download = async () => {
		const res: any = await ReportService.admin.downloadPayments({
			...this.state.form,
		});
		if (window.webkit && window.webkit.messageHandlers) {
			const message = 'open_url';
			const sendToNative = {
				message,
				url: Constants.BASE_STORAGE + res.url
			}
			const stringifiedMessageObj = JSON.stringify(sendToNative);
	        window.webkit.messageHandlers.cordova_iab.postMessage(stringifiedMessageObj);
	    }
	    else {
	    	Print(Constants.BASE_STORAGE + res.url);
	    }
	}
	
	render() {
		return (
			<div id="admin-report-payments">
				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-10">
								<div className="col-md-3">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Nombre del usuario"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<Input
										value={ this.state.form.document }
										name="document"
										placeholder="Buscar"
										label="Nº Documento Identidad"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<Input
										value={ this.state.form.location }
										name="location"
										placeholder="Buscar"
										label="Ubicación (Ciudad/Estado)"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<Input
										value={ this.state.form.san }
										name="san"
										placeholder="Buscar"
										label="Nº de San"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Pagos por participantes" data={ this.state.data.length } header={ this.state.header } titleRight={
					<React.Fragment>
						{
							this.state.data.length > 0 && (
								<div className="container-add-table">
									<p>Descargar Excel</p>
									<button className="btn add-btn-table" onClick={ this.download }>
										<img src={ DownloadExcelIcon } />
									</button>
								</div>
							)
						}
					</React.Fragment>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i?.user?.name + ' ' + i?.user?.lastname }</td>
								<td>{ (i?.user?.document_type_id != Constants.DOCUMENT_TYPES.PASSPORT ? i?.user?.document_type?.name : '') + i?.user?.document }</td>
								<td>
									<p>{ i?.user?.person?.city?.name || i?.user?.person?.state?.name || '' }</p>
									<p>{ i?.user?.person?.alternative_city?.name || i?.user?.person?.alternative_state?.name || '' }</p>
								</td>
								<td>{ i?.san?.code }</td>
								<td>{ i?.dues_paid }</td>
								<td>{ Globals.formatMiles(i?.amount_paid) }</td>
								<td>{ i?.san?.finish_date ? moment(i?.san?.finish_date).format('DD/MM/YYYY') : '-' }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(ReportPayments);