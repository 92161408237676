import { Api } from 'utils';

class PaymentService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/payments/',params),
	}

}

export default PaymentService;