import React from 'react';
import { Input, Button } from 'components';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Colors } from 'utils';
import { RouteComponentProps } from 'react-router';
import { LoginPig, BgLogin, Logo } from 'assets/img';
import Swal from 'sweetalert2';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

declare var window: any;

class Success extends React.Component<Props> {

	componentDidMount() {
		if (this.props.user) {
			this.props.history.replace('/');
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Inicio',
				onBack: null
			}
		});
	}
	
	render() {
		return (
			<div id="login">
				<div className="container-black">
					<div className="row container-form container-bg" style={{ backgroundImage: `url(${ BgLogin })` }}>
						<div className="col-lg-6 text-center">
							<div className="form-align">
								<img src={ Logo } className="logo-password" />
							</div>
						</div>
						<div className="col-lg-6 text-center">
							<div className="form-align">
								<img src={ LoginPig } className="pig" />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(Success);