import React from 'react';
import { Input, Button } from 'components';
import { connect } from 'react-redux';
import { AuthService } from 'services';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router';
import { BgLogin, LoginPig } from 'assets/img';
import { ArrowLeft, LockIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { Globals, Colors } from 'utils';

declare var window: any;

interface Props extends RouteComponentProps<any> {
	dispatch: any
}

const STEPS = {
	FIRST: 1,
	SECOND: 2
}

class Reset extends React.Component<Props> {

	state = {
		visible: false,
		form: {
			password: '',
			password_confirmation: ''
		},
		password: [],
		buttons: [],
		step: STEPS.FIRST,
		title: 'Ingrese su nueva contraseña'
	}

	async componentDidMount() {
		const res: any = await AuthService.checkCode({
			code: this.props.match.params.code
		});
		if (!res.result) {
			this.props.history.replace('/');
		}
		else {
			this.setState({
				visible: true
			});
		}

		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Recuperación de Contraseña',
				onBack: null
			}
		});

		let password: any = [...this.state.password];
		for (let i = 0; i < 6; i++) {
			password.push(null);
		}
		this.setState({
			password
		});
		let buttons: any = [...this.state.buttons];
		for (let i = 1; i <= 9; i++) {
			buttons.push({
				label: i,
				onPress: () => {
					let password: any = [...this.state.password];
					const index = password.findIndex((item: any) => item == null);
					if (index != -1) {
						password[index] = i;
					}
					this.setState({
						password
					},() => this.checkLogin());
				}
			});
		}
		buttons.push({});
		buttons.push({
			label: 0,
			onPress: () => {
				let password: any = [...this.state.password];
				const index = password.findIndex((item: any) => item == null);
				if (index != -1) {
					password[index] = 0;
				}
				this.setState({
					password
				},() => this.checkLogin());
			}
		});
		buttons.push({
			label: '<',
			onPress: () => {
				let password: any = [...this.state.password];
				const index = password.filter((i: any) => i != null).length - 1;
				if (index != -1) {
					password[index] = null;
				}
				this.setState({
					password
				},() => this.checkLogin());
			}
		});
		this.setState({
			buttons
		});
	}

	checkLogin = async () => {
		if (this.state.password.filter(item => item != null).length == 6) {
			if (this.state.step == STEPS.FIRST) {
				await this.setState({
					form: {
						...this.state.form,
						password: this.state.password.join('')
					},
					step: STEPS.SECOND,
					title: 'Repita su nueva contraseña'
				},() => this.resetPassword());
				
			}
			else if (this.state.step == STEPS.SECOND) {
				await this.setState({
					form: {
						...this.state.form,
						password_confirmation: this.state.password.join('')
					}
				},() => this.resetPassword());

				if (this.state.form.password != this.state.form.password_confirmation) {
					Globals.showError("Las contraseñas no coinciden");
					await this.setState({
						step: STEPS.FIRST,
						form: {
							password: '',
							password_confirmation: ''
						},
						title: 'Ingrese su nueva contraseña'
					},() => this.resetPassword());
					return;
				}

				this.submit();
			}
		}
	}

	resetPassword = () => {
		let password: any = [];
		for (let i = 0; i < 6; i++) {
			password.push(null);
		}
		this.setState({
			password
		});
	}

	submit = async () => {
		const form = { ...this.state.form };

		await this.setState({
			step: STEPS.FIRST,
			form: {
				password: '',
				password_confirmation: ''
			},
			title: 'Ingrese su nueva contraseña'
		},() => this.resetPassword());

		await AuthService.reset({
			code: this.props.match.params.code,
			...form
		});
		this.props.history.replace('/success-password');
		Globals.showSuccess("Se ha cambiado su contraseña correctamente");
	}
	
	render() {
		return (
			<div id="reset">
				<div className="row container-form">
					<div className="col-lg-6 container-bg" style={{ backgroundImage: `url(${ BgLogin })` }}>
						<img src={ LoginPig } className="pig" />
					</div>
					<div className="col-lg-6" onSubmit={ this.submit }>
						<div className="row row-title">
							<div className="col-2">
								<Link to="/login">
									<img src={ ArrowLeft } className="arrow-left-green" />
								</Link>
							</div>
							<div className="col-8">
								<h3 className="title-green">{ this.state.title }</h3>
							</div>
						</div>
						<form className="container-input" onSubmit={ (e: any) => e.preventDefault() }>
							<div className="passwordContainer">
								{
									this.state.password.map((item) => {
										return (
											<div className="itemPassword">
												{
													item != null && (
														<p className="value">*</p>
													)
												}
											</div>
										)
									})
								}
							</div>
							<div style={{ flex: 1, justifyContent: 'flex-end', marginBottom: '30px', marginTop: '30px' }}>
								<div className="containerNumbers">
									{
										this.state.buttons.map((item: any) => {
											return (
												<div style={{ width: '33.33%' }}>
													{
														item.onPress != null && (
															<button type="button" className="btn" onClick={ item.onPress }>
																<div className="itemNumber">
																	<p className="number">{ item.label }</p>
																</div>
															</button>
														)
													}
												</div>
											)
										})
									}
								</div>
							</div>
						</form>		
					</div>
				</div>
			</div>
		)
	}
}

const styles = {

}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Reset);