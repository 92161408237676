import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, AddIcon, EditIcon, TrashIcon } from 'assets/icons';
import ModalCreate from './modal-create';
import { SanService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	city: '',
	since: '',
	until: ''
}

class San extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Fecha de creación',
			'Código del san',
			'Ciudad',
			'Nº de participantes',
			'Monto del san',
			'Nº semanas o meses',
			'Fecha de inicio',
			'Fecha de finalización',
			''
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Creación de San',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await SanService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
			until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.sanes.rows,
			last_page: res.sanes.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	add = () => {
		this.setState({
			visible: true
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => {
			this.load(true);
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	delete = async (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el SAN?',async () => {
			await SanService.admin.delete({
				san_id: item?.id
			});	
			Globals.showSuccess("Se ha eliminado el SAN correctamente");
			this.load(true);
		});
	}
	
	render() {
		const { visible } = this.state;

		return (
			<div id="admin-san">
				<Modal
		          className="modal-create-san"
		          visible={ visible }
		          title={ `${ this.state.item != null ? 'Editar' : 'Creación de' } San por Ciudad` }
		          onClose={ () => this.onClose() }
		        >
		          <ModalCreate
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-10">
								<div className="col-md-3">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Código del san"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<Input
										value={ this.state.form.city }
										name="city"
										label="Ciudad"
										placeholder="Buscar"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-3">
									<DatePicker
										label="Desde"
										placeholder="Desde"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'since'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.since }
									/>
								</div>
								<div className="col-md-3">
									<DatePicker
										label="Hasta"
										placeholder="Hasta"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'until'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.until }
									/>
								</div>
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Sanes Registrados" data={ this.state.data.length } header={ this.state.header } titleRight={
					<div className="container-add-table">
						<p>Nuevo</p>
						<button className="btn add-btn-table" onClick={ this.add }>
							<img src={ AddIcon } />
						</button>
					</div>
				}>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ i.code }</td>
								<td>{ i.city?.name || i.state?.name || '-' }</td>
								<td>{ i.quantity_persons }</td>
								<td>{ Globals.formatMiles(i.amount) }</td>
								<td>{ i.days_or_weeks } { i.frequency == Constants.FREQUENCIES.WEEK ? 'semana(s)' : 'mes(es)' }</td>
								<td>{ i.start_date ? moment(i.start_date).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.finish_date ? moment(i.finish_date).format('DD/MM/YYYY') : '-' }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Editar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.edit(e,i) }>
											<img src={ EditIcon } />
										</a>
									</Tooltip>
									<Tooltip title="Eliminar">
										<a className="link-icon" href="#" onClick={ (e: any) => this.delete(e,i) }>
											<img src={ TrashIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(San);