import { Api } from 'utils';

class AuthService {
	
	static login = async (params?: any) => Api.createResponse('auth/login',params);
	static checkCode = async (params?: any) => Api.createResponse('app/auth/check-code',params);
	static recover = async (params = {}) => Api.createResponse('auth/recover',params);
	static reset = async (params = {}) => Api.createResponse('app/auth/reset',params);
	static checkUser = async (params = {}) => Api.createResponse('auth/check-user',params);

}

export default AuthService;