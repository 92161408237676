import { Api } from 'utils';

class UserService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/users/',params),
		update: async (params?: any) => Api.createResponse('admin/users/update',params),
		delete: async (params?: any) => Api.createResponse('admin/users/delete',params),
		downloadExcel: async (params?: any) => Api.createResponse('admin/users/download-excel',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/users/change-status',params),
		getPhonecodes: async (params?: any) => Api.createResponse('admin/users/phonecodes',params),
		requestDocuments: async (params?: any) => Api.createResponse('admin/users/request-documents',params),
		credolabSettings: async (params?: any) => Api.createResponse('admin/users/credolab-settings',params),
		getCredolab: async (params?: any) => Api.createResponse('admin/users/credolab',params),
	}

}

export default UserService;