import React from 'react';
import { Input, Button, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalLocation extends React.Component<Props> {
	
	render() {
		return (
			<div id="modal-users-google-maps">
				<div className="row">
					<div className="col-md-12">
						<p>Dirección</p>
						<Input
							disabled={ true }
							value={ this.props.item?.person?.state?.name || '' }
							placeholder="Estado"
							label="Estado"
							onChange={ (e: any) => {} } />
						<Input
							disabled={ true }
							value={ this.props.item?.person?.city?.name || '' }
							placeholder="Ciudad"
							label="Ciudad"
							onChange={ (e: any) => {} } />
						<Textarea
							rows={ 3 }
							disabled={ true }
							value={ this.props.item?.person?.address || '' }
							placeholder="Dirección"
							label="Dirección"
							onChange={ (e: any) => {} } />
					</div>
					{/*<div className="col-md-6">
						<p>Dirección alternativa</p>
						<Input
							disabled={ true }
							value={ this.props.item?.person?.alternative_state?.name || '' }
							placeholder="Estado"
							label="Estado"
							onChange={ (e: any) => {} } />
						<Input
							disabled={ true }
							value={ this.props.item?.person?.alternative_city?.name || '' }
							placeholder="Ciudad"
							label="Ciudad"
							onChange={ (e: any) => {} } />
						<Textarea
							rows={ 3 }
							disabled={ true }
							value={ this.props.item?.person?.alternative_address || '' }
							placeholder="Dirección"
							label="Dirección"
							onChange={ (e: any) => {} } />
					</div>*/}
				</div>
			</div>
		)
	}
}

export default ModalLocation;