import { Api } from 'utils';

class PositionService {
		
	static admin = {
		get: async (params?: any) => Api.createResponse('admin/positions/',params),
		view: async (params?: any) => Api.createResponse('admin/positions/view',params),
		savePositions: async (params?: any) => Api.createResponse('admin/positions/save-positions',params),
		changeStatus: async (params?: any) => Api.createResponse('admin/positions/change-status',params),
		delete: async (params?: any) => Api.createResponse('admin/positions/delete',params),
	}

}

export default PositionService;