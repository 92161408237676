export default {
	BASE_STORAGE: process.env.REACT_APP_BASE_STORAGE,
	BASE_API: process.env.REACT_APP_BASE_API,
	BASE_PUBLIC: process.env.REACT_APP_BASE_PUBLIC,
	TIMEZONE: 'America/Caracas',
	LEVELS: {
		ADMIN: 1,
		MODERATOR: 2,
		PATIENT: 4
	},
	USER: {
		VERIFIED: {
			PENDING: 0,
			APPROVED: 1,
			REJECTED: 2,
		},
		STATUS: {
			PENDING: 0,
			ACTIVE: 1,
			INACTIVE: 2,
		}
	},
	MODULES: {
		
	},
	FREQUENCIES: {
		WEEK: 1,
		MONTH: 2
	},
	DOCUMENT_TYPES: {
		V: 1,
		E: 2,
		G: 3,
		J: 4,
		PASSPORT: 5
	},
	VERIFIED_STATUS: {
	    PENDING: 0,
	    VERIFIED: 1,
	    REJECTED: 2
	},
	STATUS_PAYMENT: {
		PENDING: 1,
		IN_PROGRESS: 2,
		FINISH: 3,
		CANCELLED: 4
	},
	IMPORTANT_POSITIONS: [1,2,3]
}