import React from 'react';
import { Input, Button, Select } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { AccreditationService } from 'services';
import moment from 'moment';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalView extends React.Component<Props> {

	state = {
		san: null,
		accreditations: [],
		payments: [],
		current_date: undefined
	}

	componentDidMount() {
		this.load();
	}

	load = async (withoutLoading = false) => {
		const res: any = await AccreditationService.admin.view({
			san_id: this.props.item?.id,
			withoutLoading
		});
		this.setState({
			san: res.san,
			accreditations: res.accreditations || [],
			payments: res.payments || [],
			current_date: res.current_date || undefined
		});
	}

	accreditation = (item: any) => {
		Globals.confirm('¿Desea acreditar al usuario?',async () => {
			await AccreditationService.admin.save({
				san_id: this.props.item?.id,
				user_id: item?.user_id
			});
			Globals.showSuccess("Se ha acreditado al usuario correctamente");
			this.load(true);
		});
	}

	checkPayment = (due_number: number, date: string, user_id: number) => {
		let name = '';
		const paid = this.state.payments.find((i: any) => i.due_number == due_number && i.user_id == user_id) != null;

		const is_pending = moment(this.state.current_date).format('YYYY-MM-DD') > moment(date).format('YYYY-MM-DD') && !paid;

		if (is_pending) {
			name = 'td-gray';
		}
		else if (paid) {
			name = 'td-green';
		}

		return name;
	}

	getStatus = (due_number: number) => {
		let name = 'Pendiente';

		const payments = this.state.payments.filter((i: any) => i.due_number == due_number);

		// @ts-ignore
		if (payments.length >= this.state.san?.positions?.length) {
			name = "Completo";
		}

		return name;
	}

	getDate = (due_number: number) => {
		// @ts-ignore
		return moment(this.state.san?.start_date).add(due_number - 1,this.state.san?.frequency == Constants.FREQUENCIES.WEEK ? 'weeks' : 'months').format('DD/MM/YYYY')
	}
	
	render() {
		const san: any = this.state.san;
		const show_button = san?.positions?.filter((i: any) => !i.position).length == 0;

		return (
			<div id="modal-view-accreditations">
				<div className="container-header">
					<p className="title">Balance de pagos</p>
					<p className="subtitle"><strong>Estatus:</strong> { san?.status_payment_text }</p>
					<p className="subtitle">San: { san?.code }</p>
				</div>
				<div className="row">
					<div className="col-2">
						<p className="header">Participantes</p>
					</div>
					<div className="col-8">
						<p className="header">Pagos cuotas { san?.frequency == Constants.FREQUENCIES.WEEK ? 'semanales' : 'mensuales' }</p>
					</div>
					<div className="col-2">
						<p className="header">Acreditar</p>
					</div>
					<div className="col-2 bg-gray">
						<p className="bold" style={{ fontSize: '12px' }}>Nombre</p>
					</div>
					<div className="col-8 bg-gray">
						<table style={{ width: '100%' }}>
							<tr>
								{
									[...Array(Math.ceil(san?.amount / san?.dues) || 0)].map((item: number, index: number) => {
										return (
											<td className="td-week" style={{ width: ([...Array(Math.ceil(san?.amount / san?.dues) || 0)].length / 100) + '%' }}>{ index + 1 }</td>
										)
									})
								}
							</tr>
						</table>
					</div>
					<div className="col-2 bg-gray"></div>
					{
						san?.positions?.map((item: any, index: number) => {
							const accreditation = this.state.accreditations.find((i: any) => i.user_id == item.user_id);
							const accreditation_left = this.state.accreditations.find((i: any) => i.user_id == san?.positions[index - 1]?.user_id);

							return (
								<>
									<div className="col-2 bg-gray">
										<p className="bold name">{ item?.position }º { item?.user?.name + ' ' + item?.user?.lastname }</p>
									</div>
									<div className="col-8 bg-gray">
										<table style={{ width: '100%' }}>
											<tr>
												{
													[...Array(Math.ceil(san?.amount / san?.dues) || 0)].map((i: number, index: number) => {
														const date = moment(san.start_date).add(index,san.frequency == Constants.FREQUENCIES.WEEK ? 'weeks' : 'months').format('YYYY-MM-DD')

														return (
															<td className={ `td-value ${ this.checkPayment(index + 1,date,item.user_id) }` } style={{ width: ([...Array(Math.ceil(san?.amount / san?.dues) || 0)].length / 100) + '%' }}>{ Globals.formatMiles(san?.dues,true,'') }</td>
														)
													})
												}
											</tr>
										</table>
									</div>
									<div className="col-2 bg-gray">
										{
											san?.start_date != null && (
												<>
													{
														show_button && moment(this.state.current_date).format('YYYY-MM-DD') >= moment(san?.start_date).add(item.position - 1,san.frequency == Constants.FREQUENCIES.WEEK ? 'weeks' : 'months').format('YYYY-MM-DD') && accreditation == null && (accreditation_left != null || index == 0) && (
															<button className="btn btn-valid" onClick={ () => this.accreditation(item) }>
																Acreditar
															</button>
														)
													}

													{
														accreditation != null && (
															<p className="accreditation">Acreditado</p>
														)
													}
												</>
											)
										}
									</div>
								</>
							)
						})
					}
					<div className="col-2 bg-cyan">
						<p className="bold">Estatus</p>
					</div>
					<div className="col-8 bg-cyan">
						<table style={{ width: '100%' }}>
							<tr>
								{
									[...Array(Math.ceil(san?.amount / san?.dues) || 0)].map((item: number, index: number) => {
										return (
											<td className="td-week" style={{ width: ([...Array(Math.ceil(san?.amount / san?.dues) || 0)].length / 100) + '%' }}>
												<p className="status-payment" style={{ marginBottom: san?.start_date == null ? '5px' : '0px' }}>{ this.getStatus(index + 1) }</p>
												{ san?.start_date != null && <p className="status-payment">{ this.getDate(index + 1) }</p> }
											</td>
										)
									})
								}
							</tr>
						</table>
					</div>
					<div className="col-2 bg-cyan"></div>
				</div>
			</div>
		)
	}
}

export default ModalView;