import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Button, Input } from 'components';
import { CommissionService } from 'services';
import { PercentageIcon } from 'assets/icons';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Commissions extends React.Component<Props> {

	state = {
		items: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Comisiones',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await CommissionService.admin.get();
		this.setState({
			items: res.commissions || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		await CommissionService.admin.save({
			items: this.state.items
		});
		Globals.showSuccess("Se han guardado los cambios correctamente");
	}

	change = (e: any, index: number) => {
		let items: any = [...this.state.items];
		items[index][e.target.name] = e.target.value;
		this.setState({
			items
		});
	}

	getName = (index: number) => {
		switch(index) {
			case 1:
				return "Primer";
			break;

			case 2:
				return "Segundo";
			break;

			case 3:
				return "Tercer";
			break;
		}
	}
	
	render() {
		return (
			<div id="admin-commissions">
				<form className="container-white" onSubmit={ this.submit }>
					<h3>Comisiones por posición</h3>
					<div className="container-positions">
						{
							this.state.items.map((item: any, index: number) => {
								if (item.position != null) {
									return (
										<div className="row container-input">
											<div className="col-md-8">
												<p>{ this.getName(item.position) } lugar</p>
											</div>
											<div className="col-md-4">
												<Input
													searchLeft
													onSubmit={ () => {} }
													searchIcon={ PercentageIcon }
													type="percentage"
													value={ item.percentage }
													name="percentage"
													placeholder="0.0"
													onChange={ (e: any) => this.change(e,index) } />
											</div>
										</div>
									)
								}
							})
						}		
					</div>			
					<h3>Comisión por San</h3>
					<div className="container-positions">
						{
							this.state.items.map((item: any, index: number) => {
								if (item.position == null) {
									return (
										<div className="row container-input">
											<div className="col-md-8">
												<p>Porcentaje de ganancia</p>
											</div>
											<div className="col-md-4">
												<Input
													searchLeft
													onSubmit={ () => {} }
													searchIcon={ PercentageIcon }
													type="percentage"
													value={ item.percentage }
													name="percentage"
													placeholder="0.0"
													onChange={ (e: any) => this.change(e,index) } />
											</div>
										</div>
									)
								}
							})
						}
					</div>	
					<div className="text-center">
						<Button type="submit">
							Guardar
						</Button>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Commissions);