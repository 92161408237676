import React from 'react';
import { Input, Button, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { connect } from 'react-redux';
import { UserService } from 'services';

interface Props {
	onClose?: any;
}

class ModalCredolab extends React.Component<Props> {

	state = {
		form: {
			accepted: '',
			rejected: '',
			soft_rejected: ''
		}
	}

	componentDidMount() {
		this.load();
	}

	load = async () => {
		const res: any = await UserService.admin.getCredolab();
		this.setState({
			form: {
				...this.state.form,
				accepted: res?.credolab_settings?.accepted || '',
				rejected: res?.credolab_settings?.rejected || '',
				soft_rejected: res?.credolab_settings?.soft_rejected || ''
			}
		});
	}

	submit = async () => {
		await UserService.admin.credolabSettings({
			...this.state.form
		});
		Globals.showSuccess("Se ha actualizado la configuración");
		this.props.onClose();
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="modal-credolab">
				<Input
					type="number"
					value={ this.state.form.accepted }
					name="accepted"
					label="Score mínimo referencial para aceptar prestamos"
					onChange={ (e: any) => this.change(e) } />
				<Input
					type="number"
					value={ this.state.form.soft_rejected }
					name="soft_rejected"
					label="Score con posibilidades de ingreso"
					onChange={ (e: any) => this.change(e) } />
				<Input
					type="number"
					value={ this.state.form.rejected }
					name="rejected"
					label="Score máximo para aceptar ingresos de usuarios"
					onChange={ (e: any) => this.change(e) } />
				<div className="text-center">
					<Button type="submit" className="btn-save" onClick={ () => this.submit() }>
						Guardar
					</Button>
				</div>
			</div>
		)
	}
}

export default ModalCredolab;