import React from 'react';
import { Input, Button, Textarea } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants } from 'utils';
import { connect } from 'react-redux';

interface Props {
	onClose?: any;
	item?: any;
}

class ModalBank extends React.Component<Props> {
	
	render() {
		const person = this.props.item?.person;

		return (
			<div id="modal-view-bank">
				{
					!person?.payment_mobile_document_type_id &&
					!person?.payment_mobile_document &&
					!person?.payment_mobile_phonecode_id &&
					!person?.payment_mobile_phone && 
					!person?.payment_mobile_bank_id ? (
						<p>El usuario no ha configurado su pago móvil</p>
					) : (
						<>
							<p><strong>Cédula/RIF:</strong> { person?.payment_mobile_document_type?.name }{ person?.payment_mobile_document }</p>
							<p><strong>Teléfono:</strong> { person?.payment_mobile_phonecode?.name }{ person?.payment_mobile_phone }</p>
							<p><strong>Banco:</strong> { person?.payment_mobile_bank?.name }</p>
						</>
					)
				}
			</div>
		)
	}
}

export default ModalBank;