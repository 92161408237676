import React from 'react';
import { Input, Textarea, Select, Button, PaperClip, DatePicker } from 'components';
import { ProfileService, AuthService } from 'services';
import moment from 'moment';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { Globals, Constants } from 'utils';
import { RouteComponentProps } from 'react-router-dom';
import { PhoneIcon, WebIcon, InstagramIcon, FacebookIcon, LocationIcon, TimeIcon, AddIcon, TrashIcon } from 'assets/icons';
import { WhiteImage } from 'assets/img';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Profile extends React.Component<Props> {

	state = {
		form: {
			name: '',
			lastname: '',
			email: '',
	        password: '',
	        password_confirmation: '',
	        payment_mobile_document_type_id: '',
			payment_mobile_phonecode_id: '',
			payment_mobile_phone: '',
			payment_mobile_document: '',
			payment_mobile_bank_id: '',
		},
		user: null,
		phonecodes: [],
		document_types: [],
		banks: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Mi Cuenta',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const res: any = await ProfileService.get({
			user_id: this.props.user.id
		});
		this.setState({
			user: res.user,
			form: {
				...this.state.form,
				name: res.user?.name || '',
				lastname: res.user?.lastname || '',
				email: res.user?.email || '',
				payment_mobile_document_type_id: res.user?.person?.payment_mobile_document_type_id || '',
				payment_mobile_phonecode_id: res.user?.person?.payment_mobile_phonecode_id || '',
				payment_mobile_phone: res.user?.person?.payment_mobile_phone || '',
				payment_mobile_document: res.user?.person?.payment_mobile_document || '',
				payment_mobile_bank_id: res.user?.person?.payment_mobile_bank_id || '',
			},
			document_types: res.document_types || [],
			phonecodes: res.phonecodes || [],
			banks: res.banks || []
		});
	}

	submit = async (e: any) => {
		e.preventDefault();
		const res: any = await ProfileService.update({
			user_id: this.props.user.id,
			...this.state.form,
			hasFile: true
		});
		Globals.showSuccess("Se ha actualizado el perfil correctamente");
		this.props.dispatch({
			type: 'SET_USER',
			payload: res.user
		});
		this.setState({
			form: {
				...this.state.form,
				password: '',
        		password_confirmation: '',
			}
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}
	
	render() {
		return (
			<div id="profile">
				<form className="row" onSubmit={ this.submit }>	
					<div className="col-md-12">
						<div className="container-form">
							<div className="row">
								<div className="col-md-12">
									<h3>Datos Personales</h3>
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.name }
										name="name" 
										label="Nombre"
										disabled={ this.props.user?.level_id != Constants.LEVELS.ADMIN }
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.lastname }
										name="lastname" 
										label="Apellido"
										disabled={ this.props.user?.level_id != Constants.LEVELS.ADMIN }
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										color="white"
										value={ this.state.form.email }
										name="email" 
										disabled={ this.props.user?.level_id != Constants.LEVELS.ADMIN }
										label="Correo Electrónico"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										type="password"
										color="white"
										value={ this.state.form.password }
										name="password" 
										label="Cambiar contraseña (Opcional)"
										placeholder="Contraseña"
										onChange={ this.change } />
								</div>
								<div className="col-md-6">
									<Input
										type="password"
										color="white"
										value={ this.state.form.password_confirmation }
										name="password_confirmation" 
										label="Repetir contraseña"
										placeholder="Contraseña"
										onChange={ this.change } />
								</div>
								<div className="col-md-12">
									<h3>Datos Bancarios</h3>
								</div>
								<div className="col-md-6">
									<p className="label-container-phone">Teléfono</p>
									<div className="container-phone">
										<Select
											placeholder="Código"
											name="payment_mobile_phonecode_id"
											onChange={ (e: any) => this.change(e) }
											value={ this.state.form.payment_mobile_phonecode_id }
											options={ this.state.phonecodes.map((item: any) => {
												return {
													value: item.id,
													label: item.name
												}
											}) } />
										<Input
											type="number"
											value={ this.state.form.payment_mobile_phone }
											name="payment_mobile_phone"
											placeholder="Teléfono"
											maxLength={ 7 }
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
								<div className="col-md-6">
									<p className="label-container-phone">Cédula/RIF</p>
									<div className="container-phone">
										<Select
											placeholder="Tipo"
											name="payment_mobile_document_type_id"
											onChange={ (e: any) => this.change(e) }
											value={ this.state.form.payment_mobile_document_type_id }
											options={ this.state.document_types.map((item: any) => {
												return {
													value: item.id,
													label: item.name
												}
											}) } />
										<Input
											type="number"
											value={ this.state.form.payment_mobile_document }
											name="payment_mobile_document"
											placeholder="Cédula/RIF"
											onChange={ (e: any) => this.change(e) } />
									</div>
								</div>
								<div className="col-md-6">
									<Select
										placeholder="Seleccionar"
										label="Banco"
										name="payment_mobile_bank_id"
										onChange={ (e: any) => this.change(e) }
										value={ this.state.form.payment_mobile_bank_id }
										options={ this.state.banks.map((item: any) => {
											return {
												value: item.id,
												label: item.name
											}
										}) } />
								</div>
								<div className="col-12 text-center">
									<Button type="submit">
										Guardar
									</Button>													
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Profile);