import { Api } from 'utils';

class SanService {
		
	static admin = {
		getStates: async (params?: any) => Api.createResponse('admin/san/states',params),
		getCities: async (params?: any) => Api.createResponse('admin/san/cities',params),
		create: async (params?: any) => Api.createResponse('admin/san/create',params),
		update: async (params?: any) => Api.createResponse('admin/san/update',params),
		get: async (params?: any) => Api.createResponse('admin/san',params),
		delete: async (params?: any) => Api.createResponse('admin/san/delete',params),
	}

}

export default SanService;