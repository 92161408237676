import React from 'react';
import { SearchIcon } from 'assets/icons';

interface Props {
	label?: string,
	name: string,
	type?: string 
	color?: string,
	placeholder?: string,
	onChange: any,
	value?: string | number,
	onSubmit?: () => void
}

const onChange = (event: any,_onChange: (event: any) => void,type: string = 'text') => {
	if (type == 'number') {
		const regex = /^[0-9\b]+$/;
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else if (type == 'decimal') {
		const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else if (type == 'decimal-price') {
		const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
		event.target.value = event.target.value.replace('$','');
		if (regex.test(event.target.value) || event.target.value == '') {
			_onChange(event);
		}
	}
	else if (type == 'percentage') {
		const regex = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
		if (regex.test(event.target.value) || event.target.value == '') {
			if (event.target.value == '' || parseFloat(event.target.value) <= 100) {
				_onChange(event);
			}
		}
	}
	else {
		_onChange(event);
	}
}

const Input	= React.forwardRef((props: any, ref) => (
	<div className={`form-group form-group-input ${ props.className }`} style={props.containerStyle}>
		{ props.label && <label htmlFor={ props.name }>{ props.label }</label> }
		{
			props.searchLeft && !props.searchIcon ? (
				<img className="image-search-input search-left" src={ SearchIcon } onClick={ props.onSubmit } />
			) : props.searchLeft && props.searchIcon && (
				<img className="image-search-input search-left" src={ props.searchIcon } onClick={ props.onSubmit } />
			)
		}
		<input 
			ref={ ref }
			{ ...props }
			onChange={ (e) => onChange(e,props.onChange,props.type) }
			type={ props.type == 'number' ? 'text' : props.type }
			className={ `form-control ${ props.searchRight ? 'search-right' : '' } ${ props.searchLeft ? 'search-left' : '' } ${ props.color ? 'input-'+props.color : 'input-white' }` }
			name={ props.name } />
		{
			props.searchRight && !props.searchIcon ? (
				<img className="image-search-input" src={ SearchIcon } onClick={ props.onSubmit } />
			) : props.searchRight && props.searchIcon && (
				<img className="image-search-input" src={ props.searchIcon } onClick={ props.onSubmit } />
			)
		}
	</div>
))

export default Input;