import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon } from 'assets/icons';
import { PaymentService } from 'services';
import moment from 'moment';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	search: '',
	date: '',
	san: '',
	status: ''
}

class Payments extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nombre de Usuario',
			'Nº de Cédula',
			'Nº de San',
			'Fecha de pago',
			'Monto $',
			'Método de pago',
			'Nº de referencia',
			'Nº de cuota',
			'Fecha de corte',
			'Estatus'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Gestión de Pagos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await PaymentService.admin.get({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD')
		});
		this.setState({
			data: res.payments.rows,
			last_page: res.payments.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}
	
	render() {
		return (
			<div id="admin-payments">
				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="row col-md-10">
								<div className="col-md-4">
									<Input
										value={ this.state.form.search }
										name="search"
										placeholder="Buscar"
										label="Nombre usuario"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
								<div className="col-md-4">
									<DatePicker
										label="Fecha de pago"
										placeholder="Fecha"
										onChange={ (text: string) => {
											this.change({
												target: {
													value: text,
													name: 'date'
												}
											},() => this.load(true));
										} }
										value={ this.state.form.date }
									/>
								</div>
								<div className="col-md-4">
									<Input
										value={ this.state.form.san }
										name="san"
										placeholder="Buscar"
										label="Nº de SAN"
										className="input-table"
										onSubmit={ () => this.load() }
										onChange={ (e: any) => {
											clearTimeout(this.timer);
											this.timer = setTimeout(() => {
												this.load(true);
											},1000);
											this.change(e); 
										} } />
								</div>
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				<Table title="Pagos Registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.user?.name + ' ' + i.user?.lastname }</td>
								<td>{ i.user?.document_type?.name + i.user?.document }</td>
								<td>{ i.san?.code }</td>
								<td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
								<td>{ Globals.formatMiles(i.total) }</td>
								<td>{ i.payment_method?.name }</td>
								<td>{ i.binance_payment_id || i.reference || '-' }</td>
								<td>{ i.due_number }</td>
								<td>{ moment(i.expiration_date).format('DD/MM/YYYY') }</td>
								<td>{ i.status_text }</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Payments);