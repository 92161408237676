import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import axios from 'axios';
import queryString from 'query-string';
import { ReferralService } from 'services';
import { InviteImage, GooglePlayButton, AppStoreButton } from 'assets/img';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class Referral extends React.Component<Props> {

	state = {
		points: 0,
		max: 0,
		link_android: '',
		link_ios: ''
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Referidos',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async () => {
		const params = queryString.parse(this.props.location.search);
		const data: any = await axios.get('https://api.ipify.org?format=json');
		const res: any = await ReferralService.save({
			ip: data?.data?.ip,
			code: params?.code
		});
		this.setState({
			points: res.points,
			max: res.max,
			link_android: res.link_android,
			link_ios: res.link_ios
		});
	}

	render() {
		return (
			<div id="referral">
				<div className="container">
					<h3>¡Obtén grandes beneficios al recomendar nuestra app!</h3>
					<div className="row">
						<div className="col-md-6 text-center">
							<img src={ InviteImage } className="image-invite" />
						</div>
						<div className="col-md-6">
							<p>Te damos una <strong>recompensa para ti y tus amigos</strong> que descarguen nuestra app, es muy sencillo.</p>
							{
								this.state.points > 0 && (
									<p>Busca el <strong>banner de invitaciones en la app y gana { this.state.points } puntos por cada amigo que se registre en Préstame</strong></p>
								)
							}
							<p>Ganar puntos te permitirá <strong>subir de nivel</strong> para acceder y disfrutar de nuevos beneficios.</p>
							{
								this.state.points > 0 && (
									<p><strong>Puedes invitar un máximo de { this.state.max } personas y recibir hasta { this.state.points * this.state.max } puntos</strong></p>
								)
							}
							<div className="row">
								{
									this.state.link_android && (
										<div className="col-md-6">
											<a href={ this.state.link_android } target="_blank" className="btn-shop">
												<img src={ GooglePlayButton } />	
											</a>
										</div>
									)
								}
								{
									this.state.link_ios && (
										<div className="col-md-6">
											<a href={ this.state.link_ios } target="_blank" className="btn-shop">
												<img src={ AppStoreButton } />	
											</a>
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default connect()(Referral);