import Calendar from './calendar.png';
import PaperclipIcon from './paperclip.png';
import UserIcon from './user.png';
import ArrowLeft from './arrow-left.png';
import LockIcon from './lock.png';
import PasswordInactive from './password-inactive.png';
import PasswordActive from './password-active.png';
import LogoutIcon from './logout.png';
import ProfileIcon from './profile.png';
import DownloadIcon from './download.png';
import EditIcon from './edit.png';
import AddIcon from './add.png';
import SearchIcon from './search.png';
import TrashIcon from './trash.png';
import ArrowUp from './arrow-up.png';
import ArrowBottom from './arrow-bottom.png';
import ViewIcon from './view.png';
import MailIcon from './mail.png';
import TimeIcon from './time.png';
import ArrowBottomBlack from './arrow-bottom-black.png';
import ArrowUpBlack from './arrow-up-black.png';
import CalendarWhite from './calendar-white.png';
import DeleteIcon from './delete.png';
import CalendarRed from './calendar-red.png';
import CloseIcon from './close.png';
import CheckGreenIcon from './check-green.png';
import MenuIcon from './menu.png';
import LogoutGray from './logout-gray.png';
import ArrowLeft2 from './arrow-left2.png';
import LocationIcon from './location.png';
import PhoneIcon from './phone.png';
import FacebookIcon from './facebook.png';
import InstagramIcon from './instagram.png';
import WebIcon from './web.png';
import CleanIcon from './clean.png';
import ArrowRight from './arrow-right.png';
import UploadIcon from './upload.png';
import ArrowLeftBlack from './arrow-left-black.png';
import ArrowRightBlack from './arrow-right-black.png';
import CheckWhiteIcon from './check-white.png';
import StarIcon from './star.png';
import StarActiveIcon from './star-active.png';
import XLSIcon from './xls.png';
import Download2Icon from './download2.png';
import EyeBlackIcon from './eye-black.png';
import DownloadBlackIcon from './download-black.png';
import AlertIcon from './alert.png';
import ModeratorIcon from './moderator.png';
import EditBlueIcon from './edit-blue.png';
import PrintIcon from './print.png';
import AssignIcon from './assign.png';
import IconUsers from './icon-users.png';
import UserSettings from './user-settings.png';
import DownloadExcelIcon from './download-excel.png';
import GoogleMapsIcon from './google-maps.png';
import PercentageIcon from './percentage.png';
import BankIcon from './bank.png';
import ListIcon from './list.png';
import PaymentIcon from './payments.png';
import DocumentIcon from './documents.png';

// Menu
import Menu1 from './menu/menu1.png';
import Menu2 from './menu/menu2.png';
import Menu3 from './menu/menu3.png';
import Menu4 from './menu/menu4.png';
import Menu5 from './menu/menu5.png';
import Menu6 from './menu/menu6.png';
import Menu7 from './menu/menu7.png';
import Menu8 from './menu/menu8.png';

const Menu = {
	Menu1,
	Menu2,
	Menu3,
	Menu4,
	Menu5,
	Menu6,
	Menu7,
	Menu8
}

export {
	Calendar,
	ArrowLeft,
	PaperclipIcon,
	UserIcon,
	LockIcon,
	PasswordInactive,
	PasswordActive,
	LogoutIcon,
	ProfileIcon,
	DownloadIcon,
	EditIcon,
	AddIcon,
	SearchIcon,
	TrashIcon,
	ArrowUp,
	ArrowBottom,
	ViewIcon,
	MailIcon,
	TimeIcon,
	ArrowBottomBlack,
	ArrowUpBlack,
	CalendarWhite,
	DeleteIcon,
	CalendarRed,
	CloseIcon,
	CheckGreenIcon,
	Menu,
	MenuIcon,
	LogoutGray,
	ArrowLeft2,
	LocationIcon,
	WebIcon,
	InstagramIcon,
	FacebookIcon,
	PhoneIcon,
	CleanIcon,
	ArrowRight,
	UploadIcon,
	ArrowLeftBlack,
	ArrowRightBlack,
	CheckWhiteIcon,
	StarActiveIcon,
	StarIcon,
	XLSIcon,
	Download2Icon,
	EyeBlackIcon,
	DownloadBlackIcon,
	AlertIcon,
	ModeratorIcon,
	EditBlueIcon,
	PrintIcon,
	AssignIcon,
	IconUsers,
	UserSettings,
	DownloadExcelIcon,
	GoogleMapsIcon,
	PercentageIcon,
	BankIcon,
	PaymentIcon,
	ListIcon,
	DocumentIcon
}